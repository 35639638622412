<template>
  <div class="home">
    <el-container :style="{ display: 'flex', flexWrap: 'wrap' }">
      <div class="el-icon-discount" />

      <div class="crumbs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item separator-class="el-icon-arrow-right" />
          <el-breadcrumb-item
            v-for="item in routeArr"
            :key="item.title"
            :to="{ path: item.path }"
          >
            {{ item.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="head">
        <el-header>
          <div class="header-logo">
            <img src="../assets/img/wt.png" alt="" />
            <div class="line" />
            <div class="designation">腾云管家大数据 SaaS服务系统</div>
          </div>
          <div class="toggleBtn">
            <i :class="'el-icon-s-fold'" :style="{ color: '#fff' }" />
          </div>
        </el-header>
        <headero />
      </div>

      <el-container>
        <el-aside class="asideWi">
          <el-menu
            class="el-menu-vertical-demo"
            background-color="#fff"
            text-color="#666666"
            active-text-color="#f05"
            router
            @select="selectMenu"

          >
            <template v-for="(item, index) in menuList">
              <el-submenu
                v-if="item.children.length > 0"
                :key="index"
                :index="index.toString()"
                class="menu-item"
              >
                <template slot="title" >
                  <img :src="item.icon" alt="" />
                  <span class="menu-item">{{ item.name }}</span>
                </template>

                <el-menu-item
                  v-for="(v, i) in item.children"
                  :key="`${i}-0`"
                  class="menu-item"
                  :route="v.path"
                  :index="`${index}-${i}`"
                >
                  {{ v.name }}
                </el-menu-item>
              </el-submenu>
              <template v-else>
                <el-menu-item :key="index" :index="item.path">
                  <template slot="title">
                    <img :src="item.icon" alt="" />
                    <span>{{ item.name }}</span>
                  </template>
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </el-aside>
        <div class="context" :style="{ flex: '1' }">
          <keep-alive :include="cacheList">
            <router-view />
          </keep-alive>
        </div>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import headero from '../components/headero.vue'
// import { menuList } from '@/assets/js/menuList.js'
import {getmenuList}  from '@/router/menuMain.js'
import { mapState } from 'vuex'
import { eventBus } from '@/main' 

export default {
  name: 'Home',
  components: {
    headero,
  },
  
  data() {
    return {
      routeArr: [],
      // menuList,
      menuList: [],
      is_menu_to_page: false, //是否通过左侧导航栏进去的其他页面

      is_menu_to_indoor: false, //是否通过左侧导航进入云质控页面
      is_menu_to_panel: false, //是否通过左侧导航进入质控面板页面
      isMenuGoDataBoard: false,
    }
  },
  computed: {
    ...mapState(['cacheList']),
  },
  mounted() {
    /* egfr加权限后 */
    // this.menuList = getmenuList();
    /* egfr不加权限通过账号所属机构进行判断 */
    let user_info = JSON.parse(localStorage.getItem('userInfo'))
    let menu_list = getmenuList();
    let hasEGFR = menu_list.some(obj => obj.name === "eGFR")
    if([1,10000].includes(user_info.organization) && !hasEGFR){
      menu_list.push({
        priority: 3,
        name: 'eGFR',
        icon: require('@/assets/img/seachtb.png'),
        img: require('@/assets/img/journal.png'),
        path: '/eGFR',
        children: [],
      })
      menu_list.sort((a,b)=> a.priority>b.priority?1:-1)
      this.menuList = menu_list;
    }else {
      this.menuList = getmenuList();
    }
    let roleId = user_info.org_post
    if([110, 103, 109].includes(roleId)){
      menu_list.forEach(menu=>{
        if(menu.name == '资产'){
          menu.children.push({
            priority: 3,
            name: '资产统计',
            icon: require('@/assets/img/assetmgt.png'),
            img: require('@/assets/img/assetmgt.png'),
            path: '/assetStatistics/index',
            children: [],
          })
        }
      })
    }

  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.routeArr.push ({
          title: val.meta.title,
          path: val.fullPath,
        })
        if(!this.is_menu_to_page&&this.routeArr.length>1){
          /* 非点击导航栏进入页面时 */
          // 从质控面板进入室内质控
          if(val.path==='/Indoor'){
            this.is_menu_to_page = false;
            return
          }
          // 判断是不是从质控面板进入室内又返回
          if(val.path==='/Panel'){
            setTimeout(() => {
              eventBus.$emit('searchOld', true);
            }, 100);
          }
          //进入数据看板时且不是点击导航进入时，发送信息跳转到相应位置
          if(val.path==='/dataBoard'){ 
            setTimeout(() => {
              eventBus.$emit('scrollView', true);
            }, 100);
          }

        }
        this.is_menu_to_page = false;
        // 非点击左侧导航栏进入页面时，
        // if(!this.is_menu_to_indoor){
        //   console.log(this.is_menu_to_indoor,this.routeArr)
        //   if(this.routeArr.length == 2){ //判断是是不是通过 质控面板-室内质控 进入的 
        //     if(this.routeArr[1].path.split('?')[0] == '/Indoor'&&this.routeArr[0].path.split('?')[0] == '/Panel'){
        //       return
        //     }
        //   }
        //   // if(this.routeArr.length == 3){ //判断是是不是从 质控面板-室内质控 返回的
        //   //   if(this.routeArr[2].path.split('?')[0] == '/Indoor'&&this.routeArr[1].path.split('?')[0] == '/Panel'){
        //   //     this.routeArr.splice(2,2)
        //   //     return
        //   //   }
        //   // }

        //   this.is_menu_to_indoor = false;
        // }
        

        // if(val.path==='/dataBoard'){ //进入数据看板时且不是点击导航进入时，发送信息跳转到相应位置
        //   if(this.isMenuGoDataBoard||oldVal.path==val.path){
        //     this.isMenuGoDataBoard = false;
        //   }else {
        //     setTimeout(() => {
        //       eventBus.$emit('scrollView', true);
        //     }, 100);
        //   }
        // }

        if (val.meta.isBreadcrumb) {
          this.routeArr = [
            {
              title: val.meta.title,
              path: val.fullPath,
            },
          ]
        }
        if (val.fullPath === '/') {
          this.routeArr = [
            {
              title: '首页',
              path: '/',
            },
          ]
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {
    selectMenu(index,indexPath) {
      this.is_menu_to_page = true;

      // let indexText = index.split('-')
      // let tempList = this.menuList[indexText[0]]
      // if(tempList) {
      //   console.log(tempList)
      //   this.is_menu_to_panel = tempList.children[indexText[1]].path === 'Panel'
      //   this.is_menu_to_indoor = tempList.children[indexText[1]].path === 'Indoor'
      //   if(tempList.children[indexText[1]].path==='/dataBoard'){ //判断是点击导航栏进入的数据看板
      //     this.isMenuGoDataBoard = true;
      //   }
      // }
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: url(../assets/img/bg.png);
}
.crumbs {
  position: absolute;
  left: px(275);
  top: px(22);
  .el-breadcrumb {
    font-size: px(18) !important;
  }
}
.el-icon-discount {
  position: absolute;
  left: px(255);
  top: px(18);
  width: px(34);
}
.seek {
  position: absolute;
  left: px(610);
  top: px(246);
}
.btn {
  position: absolute;
  left: px(650);
  top: px(246);
}
.sheet {
  position: absolute;
  left: px(255);
  top: px(300);
}
.shipments {
  position: absolute;
  left: px(466);
  top: px(246);
}
.el-select .el-input__inner {
  height: px(50);
}
.header-logo img {
  width: px(36);
  height: px(36);
}
.line {
  width: 1px;
  height: px(18);
  background-color: white;
  margin-left: px(5);
}

.designation {
  width: 6.583333vw;
  height: px(40);
  color: #fff;
  font-size: px(16);
  color: #ffffff;
  line-height: px(20);
  margin-left: px(10);
  text-align: center;
  letter-spacing: 1px;
}
.el-dropdown {
  color: #000;
  background-color: #fff;
}
.menu-item {
  margin-left: px(10);
}
.el-menu-item {
  height: 47px;
}
.el-menu-item * {
  vertical-align: middle;
  margin-left: px(10);
}

.title {
  color: white;
  font-size: px(30);
}
.el-container {
  height: calc(100vh - px(70));
}
.el-header {
  background-image: linear-gradient(#386fff, #4acfbe);
  height: px(70) !important;
  width: px(220);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: px(5);
}
.el-header div {
  display: flex;
  align-items: center;
}
.el-aside {
  background-color: #fff;
}
.el-main {
  background-color: #e9eef3;
}
.iconfont {
  margin-right: px(20);
}
.el-menu {
  border-right: none;
}
.toggleBtn {
  line-height: px(24);
  color: #fff;
  text-align: left;
  padding-left: 0.5625vw;
  cursor: pointer;
}
.hunt {
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: px(5);
  padding: 0 px(-21);
  margin: px(14);
}

.el-button + .el-button {
  margin-left: px(8);
}
.head {
  width: 100%;
  display: flex;
}

.asideWi {
  width: px(220) !important;
}
.context {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
::v-deep .menu-item {
  font-size: px(20) !important;
}
::v-deep .el-submenu__title {
  font-size: px(20) !important;
}
::v-deep .el-menu-item {
  font-size: px(20) !important;
}
::v-deep .el-menu-item [class^='el-icon-'] {
  font-size: px(20) !important;
}
::v-deep .el-submenu [class^='el-icon-'] {
  font-size: px(20) !important;
}
</style>

<style></style>
