import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { getToken } from '@/utils/auth';
import store from '../store'
import { Form } from 'element-ui';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login', // 重定向
    component: () => import('../views/Login/Login.vue'),
  },

  // 修改密码
  {
    path: '/changePassword',
    name: 'changePassword',

    component: () => import('../views/changePassword/changePassword.vue'),
  },
  // 消息
  {
    path: '/messages',
    name: 'messages',

    component: () => import('../views/messages/messages.vue'),
  },
   // 告警详情H5页面
   {
    path: '/warnH5',
    name: 'warnH5',

    component: () => import('../views/messages/warnH5.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue'),
  },
  // {
  //   path: '/demo',
  //   name: 'demo',
  //   component: () => import('../views/bigPage/demo.vue'),
  // }
  //   临时
  //   资产管理
  // {  // 资产管理列表
  //   path: '/assetMgt/list',
  //   name: 'assetMgtList',
  //   meta:'',
  //   component: () => import('../views/AssetManagement/amList.vue'),
  // },
  // {  //资产详情
  //   path: '/assetMgt/detail',
  //   name: 'assetMgtDtl',
  //   component: () => import('../views/AssetManagement/amDetail.vue'),
  // },
  // { // 返厂查看/审批/物流
  //   path: '/assetMgt/returnFactyDtl',
  //   name: 'returnFactyDtl',
  //   component: () => import('../views/AssetManagement/returnFactyDtl.vue'),
  // },
  // {  //返厂申请
  //   path: '/assetMgt/applyReturnFacty',
  //   name: 'applyReturnFacty',
  //   component: () => import('../views/AssetManagement/applyReturnFactory.vue'),
  // },
  // {  //移机
  //   path: '/assetMgt/relocationRecord',
  //   name: 'relocationRecord',
  //   component: () => import('../views/AssetManagement/relocationRecord.vue'),
  // },
  // {  // 出库表单
  //   path: '/assetMgt/outStoreEdit',
  //   name: 'outStoreEdit',
  //   component: () => import('../views/AssetManagement/outStoreEdit.vue'),
  // },
  // {  // 出库详情-物流发货
  //   path: '/assetMgt/outStoreDtl',
  //   name: 'outStoreDtl',
  //   component: () => import('../views/AssetManagement/outStoreDtl.vue'),
  // },
    // 资产盘点
  // {  // 资产盘点列表
  //   path: '/assetCheck/list',
  //   name: 'assetCheckList',
  //   component: () => import('../views/AssetCheck/acList.vue'),
  // },
  // {  // 资产盘点新增
  //   path: '/assetCheck/edit',
  //   name: 'acEdit',
  //   component: () => import('../views/AssetCheck/acEdit.vue'),
  // },
  // {  // 资产盘点详情
  //   path: '/assetCheck/dtl',
  //   name: 'acDtl',
  //   component: () => import('../views/AssetCheck/acDtl.vue'),
  // },
  // {  // 资产盘点确认
  //   path: '/assetCheck/acConfirm',
  //   name: 'acConfirm',
  //   component: () => import('../views/AssetCheck/acConfirm.vue'),
  // },
  // {  // 资产统计
  //   path: '/assetStatistics/index',
  //   name: 'asStatistics',
  //   component: () => import('../views/AssetStatistics/index.vue'),
  // },


]
const router = new VueRouter({
  routes,
  mode: 'history',
})
router.beforeEach((to, from, next) => {
  let toPage = {
    name: to.name,
    path: to.path,
    query: to.query,
  }
  sessionStorage.setItem('toPage', JSON.stringify(toPage))
  if (to.meta.isCache == true) {
    store.commit('cache', { name: from.name, type: 1 })
    next()
  } else {
    store.commit('cache', { name: to.name, type: 0 })
    next()
  }
})
router.afterEach((to,from) => {
  // localStorage.setItem('oldPage', JSON.stringify({name:to.name,query:to.query}))
  // 从Indoor到Indoor强制刷新一次
  if(to.name == 'Indoor'&&from.name == 'Indoor'){
    location.reload() 
  }
 
})
// 路由跳转前守卫 按住alt按键则打开新页面
router.beforeEach((to, from, next) => {
  let a = false;
  document.onkeydown = e => {
    if(e.keyCode == 18) {
      a = true
    }
  }
  setTimeout(() => {
    if(a){
      window.open(to.fullPath,'_blank');
    }else{
      try{
        next();
      } catch (e) {
        console.log('调用next()这里出错了')
      }
    }
  }, 100);

 })


export default router
